<template>

  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('external_research.activity') }}</h4>
      </template>
      <template v-slot:headerAction>
        <b-button variant="primary">
          <router-link class="text-muted" :to="{ path: '/research-farmer/research-management/activity-plan'}">Back</router-link>
        </b-button>
      </template>
        <template v-slot:body>
          <b-row>
              <b-col xl="12" lg="12" sm="12">
                      <table class="table table">
                          <tbody>
                          <tr>
                            <b-th class="text-left">{{$t('external_research.proposal_id')}} :</b-th>
                            <b-td class="text-left">{{ proposal.proposal_auto_id }}</b-td>
                            <b-th class="text-left">{{$t('external_research.project_title')}} :</b-th>
                            <b-td class="text-left">{{ ($i18n.locale==='en') ? proposal.project_title : proposal.project_title_bn }}</b-td>
                            <b-th class="text-left">{{$t('external_research.thematic_area')}} :</b-th>
                            <b-td class="text-left">{{ ($i18n.locale==='en') ? proposal.thematic_area_name : proposal.thematic_area_name_bn }}</b-td>
                          </tr>
                        </tbody>
                      </table>
              </b-col>
        </b-row>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col md="12" class="table-responsive">
              <b-table thead-class="bg-primary" bordered hover responsive :items="myData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">

                <template v-slot:cell(index)="data">
                  {{ $n(data.index + 1) }}
                </template>
                <template v-slot:cell(start_date)="data">
                  <span>{{ data.item.start_date | dateFormat }}</span>
                </template>
                <template v-slot:cell(end_date)="data">
                  <span>{{ data.item.end_date | dateFormat }}</span>
                </template>
                <template v-slot:cell(status)="data">
                  <span v-if="data.item.activity_status == 0" class="text-danger">{{ currentLocale == 'en' ? 'Not Started Yet' : 'এখনো শুরু হয়নি' }}</span>
                  <span v-else class="badge badge-success">{{ currentLocale == 'en' ? 'Ongoing' : 'চলমান' }}</span>
                </template>
                <template v-slot:cell(attachment)="data">
                  <span v-if="proposal.is_report_submit > 0">
                    {{ currentLocale == 'bn' ? 'প্রজেক্ট রিপোর্ট জমা হয়েছে' : 'Project Report Submitted' }}
                  </span>
                  <span v-else>
                    <b-button v-b-modal.modal-5 type="button" variant="warning" size="sm" :title="progressTitle" @click="edit(data.item)">
                        {{ currentLocale == 'en' ? 'Update Progress' : 'অগ্রগতি আপডেট করুন' }}
                    </b-button>
                  </span>
                </template>
                <template v-slot:cell(action)="data">
                  <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" :title="$t('globalTrans.remarks')" @click="details(data.item)"><i class="ri-eye-line m-0"></i></b-button>
                </template>

              </b-table>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
    </iq-card>

    <b-modal id="modal-4" size="xl" :title="modifyActivityTitle"  :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <ProgessDetail :editItemId="editItemId" :item="item" :key="editItemId" />
    </b-modal>

    <b-modal id="modal-5" size="lg" :title="progressTitle"  :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <DocForm :editItemId="editItemId" :item="item" />
    </b-modal>
  </b-container>
</template>
<script>

import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { proposalActivitiesApi } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ProgessDetail from './ProgessDetail'
import DocForm from './DocForm'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    ProgessDetail,
    DocForm
  },
  data () {
    return {
      myData: [],
      proposalId: 0,
      editItemId: 0,
      proposal: {},
      item: {}
    }
  },
  created () {
    if (this.$route.query.id) {
      this.proposalId = this.$route.query.id
      this.proposal = this.getProjectData()
      this.loadData()
    }
  },
  computed: {
    modifyActivityTitle () {
        return this.$t('external_research.activity') + ' ' + this.$t('external_research.progress') + ' ' + this.$t('sidebar.information') + ' ' + this.$t('globalTrans.details')
    },
    progressTitle () {
        return this.$t('external_research.activity') + ' ' + this.$t('external_research.progress') + ' ' + this.$t('sidebar.information')
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('external_research.activity_name'), class: 'text-center' },
          { label: this.$t('external_research.specific_objectives'), class: 'text-center' },
          { label: this.$t('external_research.start_date'), class: 'text-center', thStyle: 'width: 12%' },
          { label: this.$t('external_research.end_date'), class: 'text-center', thStyle: 'width: 12%' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('external_research.progress'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'activity_name_bn' },
          { key: 'specific_obj_bn' },
          { key: 'start_date' },
          { key: 'end_date' },
          { key: 'status' },
          { key: 'attachment' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'activity_name' },
          { key: 'specific_obj_en' },
          { key: 'start_date' },
          { key: 'end_date' },
          { key: 'status' },
          { key: 'attachment' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    getProjectData () {
        const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.$route.query.id))
        return JSON.parse(JSON.stringify(tmpData))
    },
    edit (item) {
      this.editItemId = item.id
      this.item = item
    },
    details (item) {
      this.editItemId = item.id
      this.item = {
        activity_name: item.activity_name,
        activity_name_bn: item.activity_name_bn,
        specific_obj_en: item.specific_obj_en,
        specific_obj_bn: item.specific_obj_bn,
        start_date: item.start_date,
        end_date: item.end_date
      }
    },
    searchData () {
      this.loadData()
    },
    loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, `${proposalActivitiesApi}/${this.proposalId}`).then(response => {
        if (response.success) {
          this.myData = this.getRelationalData(response.data)
          // this.$store.dispatch('setList', this.getRelationalData(response.data))
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
        const tmpObj = item.proposal.description.details.find(el => el.id === item.specific_obj_id)
        const newData = {
          specific_obj_en: tmpObj !== undefined ? tmpObj.specific_objective : '',
          specific_obj_bn: tmpObj !== undefined ? tmpObj.specific_objective_bn : ''
        }
        return Object.assign({}, item, newData)
      })
      return listData
    }
  }
}
</script>
