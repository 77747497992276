<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="docFormLoader">
                  <!-- Form -->
                  <b-row>
                      <b-col xl="12" lg="12" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                            <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                              <b-row>
                                <b-col xl="6" lg="6" sm="12">
                                  <ValidationProvider name="Progress Percentage" vid="progress" :rules="{required: true, max_value: document.progress_percentage > 100, regex: /^\d*\.?\d*$/}">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="12"
                                        label-for="progress"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                          {{ $t('external_research.progress_percentage') + ' (%)' }} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                        v-model="document.progress_percentage"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                      </b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col xl="6" lg="6" sm="12">
                                  <ValidationProvider name="Attachment" vid="attachment" ref="attachment">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="12"
                                        label-for="attachment"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                          {{ $t('globalTrans.attachment') }}
                                      </template>
                                      <b-form-file
                                        id="attachment"
                                        accept=".svg, .pdf, .doc, .docx"
                                        v-model="document.attachment"
                                        v-on:change="onFileChange"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-file>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>

                                <b-col xl="12" lg="12" sm="12">
                                  <ValidationProvider name="Description" vid="description">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="12"
                                        label-for="description"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                          {{ $t('globalTrans.description') }}
                                      </template>
                                      <b-textarea
                                        v-model="document.description"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                      </b-textarea>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                              </b-row>
                              <div class="row">
                                <div class="col text-right">
                                    <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.save') }}</b-button>
                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                                </div>
                              </div>
                            </b-form>
                        </ValidationObserver>
                      </b-col>
                  </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { storeActivityDocApi } from '../../../api/routes'

export default {
  props: ['editItemId', 'item'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.editItemId && this.item) {
      this.activityId = this.editItemId
    }
  },
  mounted () {
  },
  data () {
    return {
      docFormLoader: false,
      activityId: 0,
      document: {
        progress_percentage: '',
        description: '',
        attachment: [],
        attachment_file: ''
      },
      //
      activityDocuments: []
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
      onFileChange (e) {
        const format = ['svg', 'pdf', 'doc', 'docx', 'xls', 'xlsx']
        const input = e.target
        if (input.files && input.files[0]) {
          const ext = input.files[0].name.slice((input.files[0].name.lastIndexOf('.') - 1 >>> 0) + 2)
          const isSupport = format.find(el => el === ext)
          if (typeof isSupport !== 'undefined') {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.document.attachment_file = e.target.result
            }
            reader.readAsDataURL(input.files[0])
          } else {
            this.document.attachment = []
            this.document.attachment_file = ''
          }
        } else {
            this.document.attachment_file = ''
        }
    },
    async saveData () {
      this.docFormLoader = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      result = await RestApi.postData(agriResearchServiceBaseUrl, `${storeActivityDocApi}/${this.activityId}`, this.document)
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-5')
      } else {
        this.docFormLoader = false
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
