<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
          <b-overlay :show="formLoader">
              <b-row class="mb-2">
                  <b-col v-if="item" xl="12" lg="12" sm="12">
                      <!-- <b-col class="mb-3"> -->
                          <h5><strong>{{ $t('external_research.activity_name') }}</strong>: {{ (currentLocale == 'bn') ? item.activity_name_bn : item.activity_name }}</h5>
                          <h6><strong>{{ $t('external_research.specific_objectives') }}</strong>: {{ (currentLocale == 'bn') ? item.specific_obj_bn : item.specific_obj_en }}</h6>
                          <h6>
                            <strong>{{ $t('external_research.activity') + ' ' + $t('research_manage.duration') }}</strong>:
                            <span>{{ item.start_date | dateFormat }}</span>
                            <span> - {{ item.end_date | dateFormat }}</span>
                          </h6>
                      <!-- </b-col> -->
                  </b-col>
              </b-row>
              <hr>
              <b-row v-if="progress.length > 0">
                  <b-col xl="7" lg="7" sm="12">
                      <p class="bg-warning px-2" style="font-size: 16px">{{ currentLocale == 'en' ? 'Your Activity Progress' : 'আপনার কার্যক্রমের অগ্রগতি' }}</p>
                      <b-table-simple striped bordered small hover>
                        <thead>
                            <tr>
                                <th class="text-center" width="100px">{{ $t('globalTrans.date') }}</th>
                                <th scope="col">{{ $t('external_research.progress_percentage') + ' (%)' }}</th>
                                <th scope="col">{{ $t('globalTrans.attachment') }}</th>
                                <th scope="col">{{ $t('globalTrans.description') }}</th>
                            </tr>
                        </thead>
                          <tbody>
                             <b-tr v-for="(item, index) in progress.filter(itm => itm.is_external === 1)" :key="index">
                                  <b-td class="text-center">{{ item.created_at | dateFormat }}</b-td>
                                  <b-td>{{ $n(item.progress_percentage) }}</b-td>
                                  <b-td>
                                      <div v-if="item.attachment">
                                          <a target='_blank' :href="baseUrl + 'storage/activity-docs/' + item.attachment">{{item.attachment}} <i class="ri-download-cloud-fill"></i></a>
                                      </div>
                                  </b-td>
                                  <b-td>{{ item.description }}</b-td>
                            </b-tr>
                          </tbody>
                      </b-table-simple>
                  </b-col>
                  <b-col xl="5" lg="5" sm="12">
                      <p class="text-bold bg-success px-2" style="font-size: 16px">{{ currentLocale == 'en' ? 'Actual Progress by Monitoring Team' : 'মনিটরিং টিম দ্বারা প্রকৃত অগ্রগতি' }}</p>
                      <b-table-simple striped bordered small hover>
                        <thead>
                            <tr>
                                <th class="text-center" width="100px">{{ $t('globalTrans.date') }}</th>
                                <th scope="col">{{ $t('external_research.progress_percentage') + ' (%)' }}</th>
                                <th scope="col">{{ $t('globalTrans.description') }}</th>
                            </tr>
                        </thead>
                          <tbody>
                             <b-tr v-for="(item, index) in progress.filter(itm => itm.is_external === 0)" :key="index">
                                  <b-td class="text-center">{{ item.created_at | dateFormat }}</b-td>
                                  <b-td>{{ $n(item.progress_percentage) }}</b-td>
                                  <b-td>{{ item.description }}</b-td>
                            </b-tr>
                          </tbody>
                      </b-table-simple>
                  </b-col>
              </b-row>
          </b-overlay>
      </b-col>
    </b-row>
    <div class="row">
      <div class="col text-right">
          <b-button variant="danger" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
      </div>
    </div>
  </b-container>
</template>
<script>
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { activityDetailsApi } from '../../../api/routes'

export default {
  props: [
    'editItemId', 'item'
  ],
  components: {
  },
  data () {
    return {
      formLoader: false,
      progress: [],
      id: 0,
      baseUrl: agriResearchServiceBaseUrl
    }
  },
  created () {
    if (this.editItemId && this.item) {
      this.id = this.editItemId
      this.loadData()
    }
  },
  mounted () {
  },
  computed: {
      currentLocale: function () {
        return this.$i18n.locale
      }
  },
  methods: {
      loadData () {
          this.formLoader = true
          RestApi.getData(agriResearchServiceBaseUrl, activityDetailsApi, { id: this.id }).then(response => {
              if (response.success) {
                  this.progress = response.data
                  // this.progress = this.getRelationalData(response.data)
                  this.formLoader = false
              } else {
                this.formLoader = false
              }
          })
      }
  }
}
</script>
